import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTh } from '@fortawesome/pro-duotone-svg-icons/faTh'
import { faLink } from '@fortawesome/pro-duotone-svg-icons/faLink'
import { faShareAlt } from '@fortawesome/pro-duotone-svg-icons/faShareAlt'

import { Link } from 'react-router-dom'
import ContentDossierArchive from './ContentDossierArchive'
import ContentDossierDelete from './ContentDossierDelete'
import ContentDossierFavorite from './ContentDossierFavorite'
import ContentDossierHistory from './ContentDossierHistory'
import ContentDossierNotifications from './ContentDossierNotifications'
import ContentDossierSend from './ContentDossierSend'
import ContentDossierExport from '../../DefDossier/DefDossierToolbar/ContentDossierExport'
import { toast } from 'react-toastify'
import { faLock } from '@fortawesome/pro-duotone-svg-icons/faLock'
import ContentDossierSecureShare from './ContentDossierSecureShare'

class ContentDossierToolbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.id,
        }

        this.toastOptions = {
            position: 'bottom-center',
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }

        this.handleCopyLink = this.handleCopyLink.bind(this)
    }

    isAllowed(credential) {
        if (
            this.props.kedo.user().isSuperAdmin() ||
            this.props.kedo.env().isEnvironmentAdmin()
        ) {
            return true
        }
        return this.props.kedo
            .env()
            .isAllowedDefDossier(credential, this.props.dossier.def_dossier_id)
    }

    handleCopyLink() {
        const url = window.location.href

        navigator.clipboard.writeText(url)

        toast.success(this.props.kedo.t('Link Copied'), this.toastOptions)
    }

    renderSecureShareModal() {
        return (
            <ContentDossierSecureShare
                contentDossier={this.props.dossier}
                closeModal={() =>
                    this.setState({
                        showSecureModal: false,
                    })
                }
                kedo={this.props.kedo}
            />
        )
    }

    render() {
        const kedo = this.props.kedo
        return (
            <div className={this.props.size ? 'contentDossierButtons' : null}>
                {this.state.showSecureModal
                    ? this.renderSecureShareModal()
                    : null}

                {kedo.env().hasModule('secureshare') &&
                (kedo.isAdminOrEnvironmentAdmin() ||
                    kedo
                        .env()
                        .isAllowedDefDossier(
                            'secureshare',
                            this.props.dossier.def_dossier_id,
                            kedo.user()
                        )) ? (
                    <Dropdown className="d-inline-block">
                        <Dropdown.Toggle
                            variant="outline-primary"
                            size={this.props.modalized ? 'sm' : null}
                            id="dropdown-share"
                        >
                            {kedo.t('Share')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                className="d-flex justify-content-between align-items-center"
                                variant="outline-primary"
                                onClick={this.handleCopyLink}
                            >
                                {kedo.t('Copy link')}
                                <FontAwesomeIcon
                                    className="ml-1"
                                    icon={faShareAlt}
                                />
                            </Dropdown.Item>

                            <Dropdown.Item
                                className="d-flex justify-content-between align-items-center"
                                disabled={
                                    !kedo.isAdmin() &&
                                    !kedo.env().hasModule('secureshare')
                                }
                                onClick={() =>
                                    this.setState({
                                        showSecureModal:
                                            !this.state.showSecureModal,
                                    })
                                }
                            >
                                {kedo.t('Secure share', { ns: 'secureshare' })}
                                <FontAwesomeIcon
                                    className="ml-1"
                                    icon={faLock}
                                />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <Button
                        size={this.props.size}
                        onClick={this.handleCopyLink}
                        variant="outline-primary"
                        title={kedo.t('Copy link')}
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </Button>
                )}

                <ContentDossierSend
                    size={this.props.modalized ? 'sm' : null}
                    kedo={kedo}
                    dossier={this.props.dossier}
                />
                <ContentDossierFavorite
                    size={this.props.modalized ? 'sm' : null}
                    favorite={this.props.favorite}
                    kedo={kedo}
                    dossier={this.props.dossier}
                />
                <ContentDossierNotifications
                    size={this.props.modalized ? 'sm' : null}
                    subscribed={this.props.subscribed}
                    kedo={kedo}
                    dossier={this.props.dossier}
                />
                {this.props.defdossier.archivable &&
                this.isAllowed('archive') ? (
                    <ContentDossierArchive
                        size={this.props.modalized ? 'sm' : null}
                        kedo={kedo}
                        dossier={this.props.dossier}
                    />
                ) : null}
                {this.isAllowed('export') ||
                this.isAllowed('export_csv') ||
                this.isAllowed('export_pdf') ||
                this.isAllowed('export_word') ||
                this.isAllowed('export_hml') ? (
                    <ContentDossierExport
                        size={this.props.modalized ? 'sm' : null}
                        defDossier={this.props.defdossier}
                        defDossierId={this.props.dossier.def_dossier_id}
                        kedo={kedo}
                        onClose={() =>
                            this.setState({ showExportModal: false })
                        }
                        selected={[this.props.id]}
                        mode={'dossier'}
                    />
                ) : null}
                {this.isAllowed('dossierlog') ? (
                    <ContentDossierHistory
                        size={this.props.modalized ? 'sm' : null}
                        kedo={kedo}
                        dossier={this.props.dossier}
                    />
                ) : null}
                {kedo.isAdminOrEnvironmentAdmin() ? (
                    <Link
                        to={`/fieldeditor/${this.props.dossier.def_dossier_id}`}
                    >
                        <Button
                            size={this.props.modalized ? 'sm' : null}
                            variant="outline-primary"
                            title={kedo.t('Field designer')}
                        >
                            <FontAwesomeIcon icon={faTh} />
                        </Button>
                    </Link>
                ) : null}
                {kedo
                    .env()
                    .isAllowedDefDossier(
                        'delete',
                        this.props.dossier.def_dossier_id,
                        kedo.user()
                    ) ? (
                    <ContentDossierDelete
                        size={this.props.modalized ? 'sm' : null}
                        onSuccess={this.props.onDeleteSuccess}
                        kedo={kedo}
                        dossier={this.props.dossier}
                    />
                ) : null}
            </div>
        )
    }
}

export default ContentDossierToolbar
